<script setup></script>

<template>
  <Meta name="robots" content="noindex, nofollow, noarchive" />
  <Body class="bg-indigo-900 h-screen flex items-center justify-center" />

  <div class="min-h-full">
    <main id="auth-main">
      <slot />
    </main>
  </div>
</template>

<style scoped>
#auth-main :deep(input) {
  background: #272767 !important;
  border: 0;
  color: #fff !important;
}
#auth-main :deep(.password-toggle) {
  color: #818cf8 !important;
}
#auth-main :deep(input::placeholder) {
  color: #6366f1 !important;
}
</style>
